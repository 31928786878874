<template>
    <el-dialog
        title="选择机构商品并复制"
        :visible.sync="dialogFormVisible"
        :destroyOnClose="true"
        width="80%"
        top="60px"
        @close="close"
    >
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                ref="createForm"
                :model="form"
                :rules="rules"
                size="small"
            >
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="机构组">
                            <el-select v-model="deptGroupCode" filterable>
                                <el-option
                                    v-for="group of deptGroups"
                                    :value="group.code"
                                    :key="group.code"
                                    :label="group.name"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="源机构" prop="sourceDeptCode">
                            <el-select v-model="form.sourceDeptCode" filterable>
                                <el-option
                                    v-for="dept of sourceDepts"
                                    :value="dept.code"
                                    :key="dept.code"
                                    :label="dept.name"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="查询条件">
                            <el-input placeholder="名称/条码" v-model="search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item>
                            <el-button type="primary" @click="handleQuery" :disabled="disableQueryButton"
                                >查询</el-button
                            >
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="目标机构" prop="targetDeptCode">
                            <el-select v-model="form.targetDeptCode" filterable>
                                <el-option
                                    v-for="dept of targetDepts"
                                    :value="dept.code"
                                    :key="dept.code"
                                    :label="dept.name"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-button type="primary" @click="copyGoods" :disabled="disableCopyButton">
                            复制商品
                        </el-button>
                    </el-col>
                </el-row>
            </el-form>
            <p style="color: #e68a2e">
                操作说明：<br />
                根据机构组筛选出源机构然后查询源机构下的商品，勾选需要复制的商品，选择目标机构，点击复制商品按钮进行复制。<br />
                该批量操作在复制失败时，会返回复制失败商品原因，已复制成功的商品会成功保存。<br />
                目标机构下已勾选提交过的商品禁止再次勾选提交。
            </p>
        </el-card>

        <el-card shadow="never" style="margin-top: 8px" align="left">
            <div style="height: 500px; width: 100%">
                <el-table
                    border
                    stripe
                    style="width: 100%"
                    size="mini"
                    max-height="500px"
                    :highlight-current-row="true"
                    ref="goodsTable"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column width="40" type="selection" :selectable="selectable" />
                    <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                    <el-table-column
                        prop="sku.name"
                        label="商品名称"
                        width="180"
                        fixed="left"
                        :show-overflow-tooltip="true"
                    />
                    <el-table-column
                        prop="sku.bars"
                        label="条码"
                        width="120"
                        fixed="left"
                        :show-overflow-tooltip="true"
                    >
                        <template slot-scope="scope">
                            <span>{{ scope.row.sku.bars.map((d) => d).join(' / ') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sku.specs" label="规格" width="120" />
                    <el-table-column prop="type" label="类型" width="80">
                        <template slot-scope="scope">
                            <span>{{ scope.row.sku.type | skuTypeDesc }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="状态" width="80">
                        <template slot-scope="scope">
                            <span>{{ scope.row.status | statusDesc }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sku.category" label="类目" width="100" />
                    <el-table-column prop="sku.brand" label="品牌" width="100" :show-overflow-tooltip="true" />
                    <el-table-column prop="stock" label="库存" width="100" v-if="hasPrivilege('biz.stock.query')" />
                    <el-table-column prop="actualStock" key="actualStock" label="实际库存" width="150">
                        <template slot="header">
                            <span>实际库存</span>
                            <el-tooltip effect="dark" placement="top">
                                <div slot="content">
                                    实际库存，指门店中现有的库存，其中会包含电商销售后仍未发货或核销的库存，即实际库存=库存总数-预计欠货数量
                                </div>
                                <i class="el-icon-info"></i>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sku.unit" label="单位(小)" width="80" />
                    <el-table-column prop="sku.expirationDate" label="保质期" width="80" />
                    <el-table-column prop="factoryPrice" label="出厂价" width="100" v-if="showFactoryPrice">
                        <template slot-scope="scope">
                            <span>{{ scope.row.factoryPrice | moneyToThree }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="wholeSalePrice" label="批发价" width="100" v-if="showWholeSalePrice">
                        <template slot-scope="scope">
                            <span>{{ scope.row.wholeSalePrice | moneyToYuan }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="retailPrice" label="零售价" width="100" v-if="showRetailPrice">
                        <template slot-scope="scope">
                            <span>{{ scope.row.retailPrice | moneyToYuan }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="planCostPrice" label="计划成本" width="100" v-if="showPlanCostPrice">
                        <template slot-scope="scope">
                            <span>{{ scope.row.planCostPrice | moneyToFour }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="wmaCostPrice"
                        label="移动加权库存成本单价"
                        width="120"
                        v-if="showWmaCostPrice"
                    >
                        <template slot-scope="scope">
                            <!--移动加权成本单价默认单位为元，无需做转换-->
                            <span>{{ scope.row.wmaCostPrice }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <ef-pagination
                v-if="dialogFormVisible"
                :total="total"
                :default-limit="limit"
                :pageSizes="[10, 20, 50, 100, 150]"
                @pageChange="pageChange"
            />
        </el-card>
    </el-dialog>
</template>

<script>
import EfPagination from 'components/EfPagination';
import Util from 'js/Util';
import GoodsCommon from 'js/GoodsCommon';
import MoneyUtils from 'js/MoneyUtils';
import { packFactor } from 'js/StockBizCommon';
import { deptCodeValidateRule } from 'js/validate/ValidateCommonRule';

export default {
    name: 'CopyDeptGoods',
    components: { EfPagination },
    data() {
        return {
            deptGroupCode: '',
            search: '',
            page: 1,
            limit: 20,
            form: {
                sourceDeptCode: '',
                targetDeptCode: '',
            },
            total: 0,
            tableData: [],
            //勾选的数据
            selectedSourceDeptGoodsRows: [],
            //已经复制过的源机构与其商品rows deptCode:goodsArr
            copiedSourceDeptGoodsRowMap: new Map(),

            deptGroups: [],
            sourceDepts: [],
            targetDepts: [],

            dialogFormVisible: false,

            rules: {
                sourceDeptCode: deptCodeValidateRule(),
                targetDeptCode: deptCodeValidateRule(),
            },
        };
    },

    filters: {
        bar(bars) {
            if (bars && bars.length) {
                return bars.join('/');
            }
            return bars;
        },
        skuTypeDesc(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
        statusDesc(status) {
            return GoodsCommon.goodsStatusDesc(status);
        },
        moneyToYuan(money) {
            if (Util.isEmpty(money)) {
                return null;
            }
            return MoneyUtils.moneyToYuan(money);
        },
    },
    watch: {
        deptGroups: {
            handler(n, o) {
                this.deptGroupCode = '';
                if (!Util.isEmpty(n) && n.length > 0) {
                    //存在机构组，则将第一个作为默认值
                    this.deptGroupCode = n[0].code;
                }
            },
            immediate: true,
        },
        deptGroupCode: {
            async handler(n, o) {
                if (n) {
                    //查询机构组下所有的机构列表
                    this.sourceDepts = await this.$efApi.deptApi.companyDeptGroupRelatedDepts(n);
                } else {
                    this.sourceDepts = [];
                }
            },
            immediate: true,
        },
        sourceDepts: {
            handler(n, o) {
                this.form.sourceDeptCode = '';
                if (!Util.isEmpty(n) && n.length > 0) {
                    //存在机构，则将第一个作为默认值
                    this.form.sourceDeptCode = n[0].code;
                }
            },
            immediate: true,
        },
        'form.sourceDeptCode': {
            handler(n, o) {
                //切换机构时清空页面所有商品
                this.tableData = [];
                if (this.deptSameFlag()) {
                    this.$message.error('源机构与目标机构不能是同一机构');
                    this.form.sourceDeptCode = '';
                }
            },
            immediate: true,
        },
        'form.targetDeptCode': {
            handler(n, o) {
                if (this.deptSameFlag()) {
                    this.$message.error('源机构与目标机构不能是同一机构');
                    this.form.targetDeptCode = '';
                }
                //强制渲染页面
                this.$forceUpdate();
            },
            immediate: true,
        },
    },
    computed: {
        showFactoryPrice() {
            return this.hasPrivilege('biz.price.factory.query');
        },
        showWholeSalePrice() {
            return this.hasPrivilege('biz.price.wholeSale.query');
        },
        showRetailPrice() {
            return this.hasPrivilege('biz.price.retail.query');
        },
        showPlanCostPrice: {
            get() {
                return this.hasPrivilege('biz.price.plan.query');
            },
            set() {},
        },
        showWmaCostPrice() {
            return this.hasPrivilege('biz.price.factory.query') || this.hasPrivilege('biz.price.wholeSale.query');
        },
        disableQueryButton() {
            return Util.isEmpty(this.form.sourceDeptCode);
        },
        disableCopyButton() {
            return Util.isEmpty(this.form.sourceDeptCode) || Util.isEmpty(this.form.targetDeptCode);
        },
    },
    methods: {
        async show() {
            //查询机构组列表
            this.deptGroups = await this.$efApi.deptGroupApi.managedAllDeptGroups();
            //查询登录用户管理或归属的所有机构列表
            this.targetDepts = await this.$efApi.deptApi.managedAllDepts();
            this.dialogFormVisible = true;
        },
        close() {
            Object.assign(this.$data, this.$options.data());
        },
        async handleQuery() {
            if (!this.form.sourceDeptCode) {
                this.$message.error('请选择源机构');
                return false;
            }
            const rst = await this.$efApi.goodsApi.queryGoods({
                deptCode: this.form.sourceDeptCode,
                search: this.search,
                page: this.page,
                limit: this.limit,
            });
            const data = [];
            for (const e of rst.data) {
                const goods = new Goods();
                Util.copyProperties(e, goods);
                if (Util.isEmpty(goods[packFactor])) {
                    //为包装因子设置默认值1
                    goods[packFactor] = 1;
                }
                data.push(goods);
            }
            this.tableData = data;
            this.total = rst.count;
        },
        pageChange(page, limit) {
            this.page = page;
            this.limit = limit;
            this.handleQuery();
        },
        handleSelectionChange(selections) {
            this.selectedSourceDeptGoodsRows = selections;
        },
        deptSameFlag() {
            return !Util.isEmpty(this.form.sourceDeptCode) && this.form.sourceDeptCode === this.form.targetDeptCode;
        },
        copyGoods() {
            this.$refs.createForm.validate((valid) => {
                if (!valid) {
                    return false;
                }
                if (Util.isEmpty(this.selectedSourceDeptGoodsRows) || this.selectedSourceDeptGoodsRows.length <= 0) {
                    this.$message.error('请勾选要复制的商品');
                    return false;
                }
                this.$efApi.goodsApi
                    .copyGoods({
                        ...this.form,
                        sourceDeptGoodsCodes: this.selectedSourceDeptGoodsRows.map((e) => e.code),
                    })
                    .finally(() => {
                        //记录已提交的源机构商品row
                        const copiedSourceDeptGoodsRowsForTargetDept =
                            this.copiedSourceDeptGoodsRowMap.get(this.form.targetDeptCode) || [];
                        copiedSourceDeptGoodsRowsForTargetDept.push(...this.selectedSourceDeptGoodsRows);
                        this.copiedSourceDeptGoodsRowMap.set(
                            this.form.targetDeptCode,
                            copiedSourceDeptGoodsRowsForTargetDept
                        );
                        //清空已提交的rows
                        this.$refs.goodsTable.clearSelection();
                    });
            });
        },
        selectable(row) {
            //已经复制过的商品需要禁止点击勾选操作
            const copiedSourceDeptGoodsRows = this.copiedSourceDeptGoodsRowMap.get(this.form.targetDeptCode) || [];
            return copiedSourceDeptGoodsRows.map((e) => e.code).indexOf(row.code) < 0;
        },
    },
};

class Goods {
    constructor() {
        this.code = '';
        this.deptCode = '';
        this.deptName = '';
        this.deptType = '';
        this.customBar = '';
        this.fastBar = '';
        this.planCostPrice = null;
        this.retailPrice = null;
        this.wholeSalePrice = null;
        this.factoryPrice = null;
        this.wmaCostPrice = null;
        this.skuCode = '';
        this.status = null;
        this.bigUnit = '';
        this.packFactor = 1;
        this.stock = null;
        this.skuType = null;
        this.sku = new Sku();
    }
}

class Sku {
    constructor() {
        this.code = '';
        this.name = '';
        this.specs = '';
        this.categoryCode = '';
        this.brandCode = '';
        this.unit = '';
        this.expirationDate = '';
        this.type = 0;
        this.bars = [];
        this.brand = '';
        this.category = '';
    }
}
</script>
