<template>
    <div>
        <el-button size="mini" @click="onRowDetail(scope.row)" v-if="hasPrivilege('menu.goods.goods.open')"
            >查看
        </el-button>
        <el-button size="mini" type="warning" @click="onRowEdit(scope.row)" v-if="hasPrivilege('menu.goods.goods.edit')"
            >编辑
        </el-button>
        <el-button
            size="mini"
            type="danger"
            @click="onDelete(scope.row.code)"
            v-if="hasPrivilege('menu.goods.goods.delete')"
            >删除
        </el-button>
        <el-button
            size="mini"
            type="success"
            @click="onRowHistory(scope.row.code)"
            v-if="hasPrivilege('menu.goods.goods.open')"
            >历史
        </el-button>
        <el-button
            size="mini"
            type="success"
            @click="onBindingSupplier(scope.row.code)"
            v-if="hasPrivilege('biz.bind.supplier.query')"
            >绑定供应商
        </el-button>
        <el-button
            size="mini"
            type="success"
            @click="onSetSpecialGoods(scope.row.code, true)"
            v-if="
                hasPrivilege('biz.qingdao.specailGoods') && (!scope.row['userBuyLimit'] || scope.row.userBuyLimit <= 0)
            "
            >设置年货特价商品
        </el-button>
        <el-button
            size="mini"
            type="success"
            @click="onSetSpecialGoods(scope.row.code, false)"
            v-if="hasPrivilege('biz.qingdao.specailGoods') && scope.row.userBuyLimit > 0"
            >取消设置特价商品
        </el-button>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'TabOperate',
    props: {
        scope: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            url: {
                delete: '/goods/goods/delete',
                /* todo 2021-11-29 青岛本次年货节临时需求 */
                specialGoods: '/goods/goods/qingdaoSpecialGoods',
            },
        };
    },
    methods: {
        onRowEdit(row) {
            Util.nameJump(this, 'menu.goods.goods.edit', ['商品管理', '可售商品管理', '编辑商品'], {
                form: row,
                code: row.code,
            });
        },
        onRowDetail(row) {
            Util.nameJump(this, 'menu.goods.goods.detail', ['商品管理', '可售商品管理', '商品详情'], {
                form: row,
                code: row.code,
            });
        },
        onBindingSupplier(code) {
            this.$emit('onBindSupplier', code);
        },
        onRowHistory(code) {
            this.$emit('onHistory', code);
        },
        onDelete(code) {
            UrlUtils.DeleteRemote(this, this.url.delete, code, '确认删除当前数据吗?', () => {
                this.$message.success('操作成功');
                this.$emit('onDelete', code);
            });
        },

        onSetSpecialGoods(code, flag) {
            const str = flag ? '确认设置为特价商品吗?' : '确认取消设置特价商品吗?';
            UrlUtils.PatchRemote(this, this.url.specialGoods, { message: str, code: code, flag: flag }, null, () => {
                this.$message.success('操作成功');
                this.$emit('onSetSpecialGoods', code);
            });
        },
    },
};
</script>
