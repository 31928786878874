<template>
    <div>
        <el-table
            border
            style="width: 100%"
            :ref="table.ref"
            :id="table.id"
            :data="table.data"
            :highlight-current-row="true"
            max-height="540"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <el-table-column type="selection" width="55" align="center" fixed="left" v-if="enablePaging" />
            <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
            <el-table-column
                prop="deptName"
                key="deptName"
                v-if="ifShowColumn('deptName')"
                label="机构名称"
                fixed="left"
                width="180"
            />
            <el-table-column
                prop="sku.name"
                key="sku.name"
                v-if="ifShowColumn('sku.name')"
                label="商品名称"
                width="180"
            >
                <template slot-scope="scope">
                    {{ scope.row.sku['name'] }}
                    <el-tag type="danger" v-if="scope.row['userBuyLimit'] > 0">特价商品</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="sku.bars" v-if="ifShowColumn('sku.bars')" key="sku.bars" label="条码" width="180">
                <template slot-scope="scope">
                    <div v-for="b in scope.row.sku.bars" :key="b">{{ b }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="sku.type" v-if="ifShowColumn('sku.type')" key="sku.type" label="商品类型" width="80">
                <template slot-scope="scope">
                    <span>{{ scope.row.sku.type | goodsType }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="fastBar" key="fastBar" v-if="ifShowColumn('fastBar')" label="助记码" width="130" />
            <el-table-column
                prop="customBar"
                key="customBar"
                v-if="ifShowColumn('customBar')"
                label="自编码"
                width="130"
            />
            <el-table-column
                prop="sku.specs"
                key="sku.specs"
                v-if="ifShowColumn('sku.specs')"
                label="规格"
                width="130"
            />
            <el-table-column
                prop="sku.category"
                key="sku.category"
                v-if="ifShowColumn('sku.category')"
                label="类目"
                width="100"
            />
            <el-table-column
                prop="sku.unit"
                key="sku.unit"
                v-if="ifShowColumn('sku.unit')"
                label="单位(小)"
                width="80"
            />
            <el-table-column prop="bigUnit" key="bigUnit" v-if="ifShowColumn('bigUnit')" label="单位(大)" width="80" />
            <el-table-column
                prop="packFactor"
                key="packFactor"
                v-if="ifShowColumn('packFactor')"
                label="包装因子"
                width="130"
            />
            <el-table-column
                prop="bigUnitCount"
                v-if="hasPrivilege('biz.stock.query') && ifShowColumn('bigUnitCount')"
                key="bigUnitCount"
                label="库存箱数"
                width="80"
            >
                <template slot-scope="scope">
                    {{ lineBigCount(scope.row) }}
                </template>
            </el-table-column>
            <el-table-column
                prop="bulkCount"
                v-if="hasPrivilege('biz.stock.query') && ifShowColumn('bulkCount')"
                key="bulkCount"
                label="库存散数"
                width="80"
            >
                <template slot-scope="scope">
                    {{ lineBulkCount(scope.row) }}
                </template>
            </el-table-column>
            <el-table-column
                prop="stock"
                v-if="hasPrivilege('biz.stock.query') && ifShowColumn('stock')"
                key="stock"
                label="库存总数"
                width="100"
            >
                <template slot-scope="scope">
                    <span
                        ><b>{{ scope.row.stock }}</b></span
                    ><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <i class="el-icon el-icon-top" v-if="scope.row.stock > scope.row.maxStock" />
                    <i class="el-icon el-icon-bottom" v-if="scope.row.stock < scope.row.minStock" />
                </template>
            </el-table-column>
            <el-table-column
                prop="actualStock"
                key="actualStock"
                label="实际库存"
                width="150"
                v-if="hasPrivilege('biz.stock.query') && ifShowColumn('actualStock')"
            >
                <template slot="header">
                    <span>实际库存</span>
                    <el-tooltip effect="dark" placement="top">
                        <div slot="content">
                            实际库存，指门店中现有的库存，其中会包含电商销售后仍未发货或核销的库存，即实际库存=库存总数-预计欠货数量
                        </div>
                        <i class="el-icon-info"></i>
                    </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column
                prop="noSendOrderCount"
                key="noSendOrderCount"
                v-if="hasPrivilege('biz.stock.query') && ifShowColumn('noSendOrderCount')"
                label="冻结库存"
                width="150"
            >
                <template slot="header">
                    <span>冻结库存</span>
                    <el-tooltip effect="dark" placement="top">
                        <div slot="content">门店接单后未发货库存+未到门店自提库存</div>
                        <i class="el-icon-info"></i>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column
                prop="onlineShoppingSellCount"
                key="onlineShoppingSellCount"
                v-if="hasPrivilege('biz.stock.query') && ifShowColumn('onlineShoppingSellCount')"
                label="预计借出数量"
                width="130"
            >
                <template slot="header">
                    <span>预计借出数量</span>
                    <el-tooltip effect="dark" placement="top">
                        <div slot="content">该商品已发货数量减去退货数量，补货入库后对应减少库存。</div>
                        <i class="el-icon-info"></i>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column
                prop="onlineShoppingSettleSellCount"
                key="onlineShoppingSettleSellCount"
                v-if="hasPrivilege('biz.stock.query') && ifShowColumn('onlineShoppingSettleSellCount')"
                label="实际借出数量"
                width="130"
            >
                <template slot="header">
                    <span>实际借出数量</span>
                    <el-tooltip effect="dark" placement="top">
                        <div slot="content">
                            确认收货15天之后的订单中该商品已发货数量减去退货数量，补货入库后对应减少库存。
                        </div>
                        <i class="el-icon-info"></i>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column
                prop="sku.brand"
                key="sku.brand"
                v-if="ifShowColumn('sku.brand')"
                label="品牌"
                width="180"
            />
            <el-table-column
                prop="sku.expirationDate"
                key="sku.expirationDate"
                v-if="ifShowColumn('sku.expirationDate')"
                label="保质期"
                width="130"
            />
            <el-table-column prop="status" v-if="ifShowColumn('status')" key="status" label="状态" width="130">
                <template slot-scope="scope">
                    <span>{{ scope.row.status | status }}</span>
                </template>
            </el-table-column>
            <el-table-column
                v-if="hasPrivilege('biz.price.factory.query') && ifShowColumn('factoryPrice')"
                key="factoryPrice"
                prop="factoryPrice"
                label="出厂价"
                width="100"
            >
                <template slot-scope="scope">
                    <span>{{ fmtThree(scope.row.factoryPrice) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                v-if="hasPrivilege('biz.price.wholeSale.query') && ifShowColumn('wholeSalePrice')"
                key="wholeSalePrice"
                prop="wholeSalePrice"
                label="批发价"
                width="100"
            >
                <template slot-scope="scope">
                    <span>{{ fmtTwo(scope.row.wholeSalePrice) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                v-if="hasPrivilege('biz.price.retail.query') && ifShowColumn('retailPrice')"
                key="retailPrice"
                prop="retailPrice"
                label="零售价"
                width="100"
            >
                <template slot-scope="scope">
                    <span>{{ fmtTwo(scope.row.retailPrice) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                v-if="hasPrivilege('biz.price.plan.edit') && ifShowColumn('planCostPrice')"
                prop="planCostPrice"
                key="planCostPrice"
                label="计划成本"
                width="100"
            >
                <template slot-scope="scope">
                    <span>{{ fmtMFour(scope.row.planCostPrice) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="操作"
                header-align="center"
                :min-width="opWidth"
                v-if="ifShowColumn('operate')"
                prop="operate"
                key="operate"
                fixed="right"
            >
                <template slot-scope="scope">
                    <slot :data="scope"></slot>
                </template>
            </el-table-column>
        </el-table>
        <ef-pagination
            v-if="enablePaging"
            :total="page.total"
            :current-page="page.page"
            :default-limit="page.limit"
            v-bind="$attrs"
            v-on="$listeners"
        />
    </div>
</template>

<script>
import EfPagination from 'components/EfPagination';
import GoodsCommon from 'js/GoodsCommon';
import LocalStorageTable from 'components/mixins/LocalStorageTable';

export default {
    name: 'TabGrid',
    components: { EfPagination },
    props: {
        opWidth: {
            type: Number,
            default: 415,
        },
        enablePaging: {
            type: Boolean,
            default: true,
        },
        table: {
            type: Object,
            default: () => {
                return {
                    id: '',
                    ref: '',
                    data: [],
                    tableColumns: [],
                    initShowAllColumnFlag: true,
                };
            },
        },
        page: {
            type: Object,
            default: () => {
                return {
                    limit: 0,
                    total: 0,
                };
            },
        },
    },
    data() {
        return {
            tableData: [],
            noShowColumns: [],
        };
    },
    mounted() {
        this.noShowColumns = LocalStorageTable.getTableLocalStorageTable(
            this.$route.name,
            this.$store.state.session.code
        );
    },
    methods: {
        onPageChange() {},

        showColumn(columnProperty) {
            const show = this.noShowColumns.indexOf(columnProperty) < 0;
            return this.table.initShowAllColumnFlag || show;
        },
        updateColumns() {
            this.noShowColumns = LocalStorageTable.getTableLocalStorageTable(
                this.$route.name,
                this.$store.state.session.code
            );
        },
        ifShowColumn(columnProperty) {
            return this.showColumn(columnProperty);
        },
        getTable() {
            return this.$children[0];
        },
        lineBigCount(row) {
            if (!row.stock || !row.packFactor) {
                return '';
            }
            if (row.sku.type === GoodsCommon.SkuTypeEnum.NOT_STANDARD) {
                return '';
            }
            return parseInt(row.stock / row.packFactor);
        },
        lineBulkCount(row) {
            if (!row.stock || !row.packFactor) {
                return '';
            }
            if (row.sku.type === GoodsCommon.SkuTypeEnum.NOT_STANDARD) {
                return '';
            }
            return parseInt(row.stock % row.packFactor);
        },
    },
    filters: {
        status(status) {
            if (typeof status == 'undefined') {
                return '';
            }
            if (status == '0') {
                return '已下架';
            }
            if (status == '1') {
                return '已上架';
            }
            if (status == '2') {
                return '已禁售';
            }
        },
    },
};
</script>
