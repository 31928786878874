<template>
    <!--    todo 初始化查询有问题-->
    <div class="Goods" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 4px">
            <el-form @submit.native.prevent :inline="true" v-model="form" size="small" label-width="80px">
                <el-row>
                    <el-form-item label="库存类型">
                        <el-select placeholder="请选择" v-model="form.stockType" style="width: 180px">
                            <el-option label="请选择" value="" />
                            <el-option label="正常库存" value="1" />
                            <el-option label="库存超储" value="2" />
                            <el-option label="库存不足" value="0" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="商品类型">
                        <el-select placeholder="请选择" v-model="form.skuType" style="width: 180px">
                            <el-option label="请选择" value="" />
                            <el-option label="标品" value="0" />
                            <el-option label="非标品" value="1" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="商品状态">
                        <el-select placeholder="请选择" v-model="form.status" style="width: 180px">
                            <el-option label="请选择" value="" />
                            <el-option label="已下架" value="0" />
                            <el-option label="已上架" value="1" />
                            <el-option label="已禁售" value="2" />
                        </el-select>
                    </el-form-item>
                    <el-form-item style="float: right">
                        <el-input placeholder="名称/条码" v-model="form.search" />
                        <el-button
                            type="primary"
                            class="left10"
                            @click="handleQuery"
                            v-if="hasPrivilege('menu.goods.goods.open')"
                        >
                            搜索
                        </el-button>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item style="float: right" label-width="0">
                        <el-button type="primary" @click="handleCreate" v-if="hasPrivilege('menu.goods.goods.create')">
                            添加
                        </el-button>
                    </el-form-item>
                    <el-form-item label="机构">
                        <efn-group-dept @change="handleChangeDepts"></efn-group-dept>
                    </el-form-item>
                    <el-form-item label="供应商">
                        <el-select
                            placeholder="请选择"
                            v-model="form.supplierCode"
                            filterable
                            :style="{ width: '180px' }"
                        >
                            <el-option label="请选择" value="" />
                            <el-option v-for="bd in meta.suppliers" :label="bd.name" :value="bd.code" :key="bd.code" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="商品类目">
                        <ef-category v-model="form.categoryCode" />
                    </el-form-item>
                    <el-form-item label="商品品牌">
                        <ef-brand v-model="form.brandCode" />
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="库存状态">
                        <el-radio-group v-model="form.stockStatus" @change="changeTabClick">
                            <el-radio-button :label="1">有库存({{ page.total }})</el-radio-button>
                            <el-radio-button :label="2">无库存({{ noStockPage.total }})</el-radio-button>
                            <el-radio-button :label="4">全部商品({{ allPage.total }})</el-radio-button>
                            <el-radio-button :label="3">已选商品({{ selectData.length }})</el-radio-button>
                            <el-radio-button :label="5"
                                >无批发价,零售价或计划成本的商品({{ noPricePage.total }})
                            </el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item style="float: right">
                        <el-button
                            type="primary"
                            plain
                            @click="handleExport"
                            size="small"
                            v-if="hasPrivilege('menu.goods.goods.export')"
                        >
                            导出
                        </el-button>
                        <el-button
                            type="primary"
                            plain
                            @click="handleCopy"
                            size="small"
                            v-if="hasPrivilege('menu.goods.goods.create')"
                        >
                            复制商品
                        </el-button>
                        <el-popover class="left10" placement="left" width="100" trigger="click">
                            <el-button class="mb10" @click="handleBatchOperate">批量操作</el-button>
                            <br />
                            <el-button class="mb10" @click="setHeader">设置表头</el-button>
                            <br />
                            <el-button class="mb10" @click="handleMultiPrint">打印</el-button>
                            <br />
                            <el-button slot="reference" plain type="primary">更多</el-button>
                        </el-popover>
                    </el-form-item>
                </el-row>
            </el-form>
        </el-card>

        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setHeaderOk" />

        <el-card shadow="never" style="margin-top: 8px">
            <div v-show="form.stockStatus == 1">
                <tab-grid
                    ref="page"
                    :table="{
                        ref: 'pageTable',
                        id: 'pagePrint',
                        data: page.data,
                        tableColumns: tableColumns,
                        initShowAllColumnFlag: initShowAllColumnFlag,
                    }"
                    :page="{ total: page.total, limit: form.limit, page: page.page }"
                    :opWidth="opWidth"
                    @select="selectTableChange"
                    @select-all="selectTableChange"
                    @pageChange="pageChange"
                >
                    <template slot-scope="dt">
                        <tab-operate
                            :scope="dt.data"
                            @onBindSupplier="bindingSupplier"
                            @onHistory="rowHistory"
                            @onDelete="rowDelete"
                            @onSetSpecialGoods="rowSetSpecialGoods"
                        ></tab-operate>
                    </template>
                </tab-grid>
            </div>
            <div v-show="form.stockStatus == 2">
                <tab-grid
                    ref="noStockPage"
                    :table="{ ref: 'noStockPageTable', id: 'noStockPagePrint', data: noStockPage.data }"
                    :page="{ total: noStockPage.total, limit: form.limit, page: noStockPage.page }"
                    @select="selectTableChange"
                    @select-all="selectTableChange"
                    @pageChange="pageChange"
                >
                    <template slot-scope="dt">
                        <tab-operate
                            :scope="dt.data"
                            @onBindSupplier="bindingSupplier"
                            @onHistory="rowHistory"
                            @onDelete="rowDelete"
                            @onSetSpecialGoods="rowSetSpecialGoods"
                        ></tab-operate>
                    </template>
                </tab-grid>
            </div>
            <div v-show="form.stockStatus == 4">
                <tab-grid
                    ref="allPage"
                    :table="{ ref: 'allPageTable', id: 'allPagePrint', data: allPage.data }"
                    :page="{ total: allPage.total, limit: form.limit, page: allPage.page }"
                    @select="selectTableChange"
                    @select-all="selectTableChange"
                    @pageChange="pageChange"
                >
                    <template slot-scope="dt">
                        <tab-operate
                            :scope="dt.data"
                            @onBindSupplier="bindingSupplier"
                            @onHistory="rowHistory"
                            @onDelete="rowDelete"
                            @onSetSpecialGoods="rowSetSpecialGoods"
                        ></tab-operate>
                    </template>
                </tab-grid>
            </div>
            <div v-show="form.stockStatus == 3">
                <el-button type="primary" plain size="small" @click="handleDeleteAllSelect" class="mb10">
                    删除已勾选
                </el-button>
                <tab-grid
                    ref="selectedPage"
                    :enablePaging="false"
                    :opWidth="105"
                    key="selectData"
                    id="printSelectAll"
                    :table="{
                        ref: 'selectedTable',
                        id: 'selectedPrint',
                        data: selectData,
                    }"
                    @select="selectTableChange"
                    @select-all="selectTableChange"
                >
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="deleteSelectRow(scope.data.row.code)"
                            >取消勾选
                        </el-button>
                    </template>
                </tab-grid>
            </div>
            <div v-show="form.stockStatus == 5">
                <tab-grid
                    ref="noPricePage"
                    :table="{ ref: 'noPricePageTable', id: 'noPricePagePrint', data: noPricePage.data }"
                    :page="{ total: noPricePage.total, limit: form.limit, page: noPricePage.page }"
                    @select="selectTableChange"
                    @select-all="selectTableChange"
                    @pageChange="pageChange"
                >
                    <template slot-scope="dt">
                        <tab-operate
                            :scope="dt.data"
                            @onBindSupplier="bindingSupplier"
                            @onHistory="rowHistory"
                            @onDelete="rowDelete"
                            @onSetSpecialGoods="rowSetSpecialGoods"
                        ></tab-operate>
                    </template>
                </tab-grid>
            </div>
        </el-card>
        <Batcher ref="batcher" @onReload="reloadPageData"></Batcher>
        <goods-history ref="goodsHistory"></goods-history>
        <bind-supplier ref="bindSupplier" />
        <copy-dept-goods ref="copyDeptGoods" />
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import BindSupplier from './goods/components/BindSupplier';
import EfBrand from 'components/EfBrand';
import CopyDeptGoods from 'views/menu/goods/goods/components/CopyDeptGoods';
import EfCategory from 'components/EfCategory';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
import TabGrid from 'views/menu/goods/goods/components/TabGrid';
import TabOperate from 'views/menu/goods/goods/components/TabOperate';
import GoodsHistory from 'views/menu/goods/goods/components/GoodsHistory';
import Batcher from 'views/menu/goods/goods/components/Batcher';
import EfnGroupDept from 'components/newStyle/EfnGroupDept';

export default {
    name: 'Goods',
    components: {
        EfnGroupDept,
        Batcher,
        GoodsHistory,
        TabOperate,
        TabGrid,
        BindSupplier,
        EfBrand,
        EfCategory,
        CheckTableShowColumnDialog,
        CopyDeptGoods,
    },
    mixins: [CheckTableShowColumn],
    data: function () {
        return {
            form: {
                groupName: '',
                groupCode: '',
                stockStatus: '1',
                deptType: '',
                deptCode: '',
                search: '',
                status: '',
                stockType: '',
                skuType: '',
                brand: '',
                category: '',
                supplierCode: '',
                page: 1,
                limit: 20,
            },
            requestForm: {},
            current: {
                typeIdx: 1,
            },

            page: {
                data: [],
                page: 1,
                limit: Util.Limit,
                total: Util.Total,
                pageSizes: Util.PageSizes,
                DefaultSize: Util.DefaultPageSize,
                PageStyle: Util.PageStyle,
            },
            noStockPage: {
                data: [],
                page: 1,
                limit: Util.Limit,
                total: Util.Total,
                pageSizes: Util.PageSizes,
                DefaultSize: Util.DefaultPageSize,
                PageStyle: Util.PageStyle,
            },
            allPage: {
                data: [],
                page: 1,
                limit: Util.Limit,
                total: Util.Total,
                pageSizes: Util.PageSizes,
                DefaultSize: Util.DefaultPageSize,
                PageStyle: Util.PageStyle,
            },

            noPricePage: {
                data: [],
                page: 1,
                limit: Util.Limit,
                total: Util.Total,
                pageSizes: Util.PageSizes,
                DefaultSize: Util.DefaultPageSize,
                PageStyle: Util.PageStyle,
            },

            selectData: [],

            selectDataMap: new Map(),

            supplierData: [],
            paramsData: [],
            initSupplierCode: [],

            exportDisabled: false,
            url: {
                page: '/goods/goods/goodsPage',
                supplier: '/goods/goods/otherSupplier',
            },
            loading: false,
            meta: {
                inited: false,
                types: [],
                suppliers: [],
            },
            showSupplier: false,
            supplierGoodsCode: '',
        };
    },
    mounted() {
        const _this = this;
        UrlUtils.GoodsSupplier(_this, (data) => {
            _this.meta.suppliers = data;
        });
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },

    computed: {
        newDeptCode() {
            return this.form.deptCode;
        },
        opWidth() {
            return this.hasPrivilege('biz.qingdao.specailGoods') ? 415 : 305;
        },
    },
    watch: {
        /**切换机构的时候清除之前的所有选择*/
        newDeptCode() {
            this.handleDeleteAllSelect();
        },
    },
    methods: {
        initColumns() {
            this.tableColumns = (this.$refs.page.getTable().columns || [])
                .filter((e) => !Util.isEmpty(e.property))
                .map((e) => {
                    const _param = { property: e.property, label: e.label, show: true };
                    if (this.noShowStorageTableColumns.includes(e.property)) {
                        _param.show = false;
                    }
                    //默认每列都显示
                    return _param;
                });
            this.initShowAllColumnFlag = false;
        },
        setHeader() {
            this.showCheckTableShowColumnDialog();
        },
        setHeaderOk(columns) {
            this.setShowTableColumns(columns);
            this.$refs.page.updateColumns();
            this.$refs.noStockPage.updateColumns();
            this.$refs.allPage.updateColumns();
            this.$refs.selectedPage.updateColumns();
            this.$refs.noPricePage.updateColumns();
        },
        /*选择部门数据--------------------*/
        handleChangeDepts(depts) {
            this.form.groupCode = depts.groupCode;
            this.form.deptCode = depts.deptCode;
            this.form.deptName = depts.deptName;
            if (!this.meta.inited) {
                this.meta.inited = true;
                //初始化,查询一次
                this.handleQuery();
            }
        },

        /*操作命令*/
        handleQuery() {
            const _key = this._stockStatus2key(this.form.stockStatus);
            if (!_key) {
                return;
            }

            this.refreshAllTabCount();
            //情况所有表格
            this.page.data = [];
            this.noStockPage.data = [];
            this.allPage.data = [];
            this.noPricePage.data = [];

            this.page.page = 1;
            this.noStockPage.page = 1;
            this.allPage.page = 1;
            this.noPricePage.page = 1;

            this.requestForm = JSON.parse(JSON.stringify(this.form));
            this.requestForm.page = 1;
            this.handleQueryData(_key);
        },
        handleExport() {
            const _this = this;
            const codes = (this.$refs.selectedPage.getTable().data || []).map((a) => a.code);
            if (codes.length) {
                UrlUtils.Export(this, '可售商品管理', '/goods/goods/exportExtend', null, codes);
            } else {
                this.$confirm('没有勾选商品,将导出机构组或机构的全部商品数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        if (!(_this.requestForm.groupCode || _this.requestForm.deptCode)) {
                            this.$message.error('请选择机构或机构组查询数据后进行导出');
                            return;
                        }
                        const param = JSON.parse(JSON.stringify(_this.requestForm));
                        param.stockStatus = _this.form.stockStatus;
                        UrlUtils.Export(_this, '可售商品管理', '/goods/goods/exportExtend', _this.requestForm);
                    })
                    .catch(() => {});
            }
        },
        handleCreate() {
            Util.nameJump(this, 'menu.goods.goods.create', ['商品管理', '可售商品管理', '新建商品']);
        },
        handleCopy() {
            this.$refs.copyDeptGoods.show();
        },
        handleBatchOperate() {
            // 所有表格的选中数据去重后校验
            const refSelectArr = [
                ...this.$refs.page.$refs.pageTable.selection,
                ...this.$refs.noStockPage.$refs.noStockPageTable.selection,
                ...this.$refs.allPage.$refs.allPageTable.selection,
                ...this.$refs.noPricePage.$refs.noPricePageTable.selection,
            ];
            const removeRepeatObj = {};
            const removeRepeatArr = [];
            refSelectArr.forEach((item) => {
                if (!removeRepeatObj[item.code]) {
                    removeRepeatArr.push(item);
                    removeRepeatObj[item.code] = true;
                }
            });
            this.$refs.batcher.show(this.selectData, removeRepeatArr);
        },
        onCancelPopover() {
            this.$refs.batcher.close();
        },

        handleDeleteAllSelect() {
            this.onCancelPopover();
            this.selectDataMap = new Map();
            this.selectData = [];

            this.$nextTick(() => {
                this.$refs.page.getTable().clearSelection();
                this.$refs.noStockPage.getTable().clearSelection();
                this.$refs.allPage.getTable().clearSelection();
                this.$refs.noPricePage.getTable().clearSelection();
            });
        },

        handleQueryData(key) {
            this.requestForm.stockStatus = this.form.stockStatus;
            if (this.form.stockStatus == 5) {
                this.requestForm.noPriceFlag = true;
            } else {
                this.requestForm.noPriceFlag = null;
            }
            this.requestForm.page = this[key].page;
            this.requestForm.limit = this[key].limit;
            Util.queryTable(
                this,
                this.url.page,
                { params: this.requestForm },
                (data) => {
                    this[key].data = data.data;
                    this[key].total = data.count;
                    this.$nextTick(() => {
                        this.echoSelected(this[key].data, key);
                    });
                },
                () => {
                    this[key].data = [];
                    this[key].total = 0;
                }
            );
        },

        pageChange(page, limit) {
            const _key = this._stockStatus2key(this.form.stockStatus);
            if (!_key) {
                return;
            }
            this.form.page = page;
            this.form.limit = limit;
            this[_key].page = page;
            this[_key].limit = limit;

            this.handleQueryData(_key);
        },

        selectTableChange(selectData) {
            const _key = this._stockStatus2key(this.form.stockStatus);
            if (!_key) {
                return;
            }
            this.dealSelectData(selectData, this[_key].data);
        },
        dealSelectData(selectData, tableData) {
            this.onCancelPopover();
            const selectSet = new Set(selectData.map((p) => p.code));
            tableData.forEach((value) => {
                if (this.selectDataMap.has(value.code)) {
                    if (!selectSet.has(value.code)) {
                        this.selectDataMap.delete(value.code);
                    }
                }
                if (selectSet.has(value.code)) {
                    this.selectDataMap.set(value.code, value);
                }
            });
            this.selectData = [...this.selectDataMap.values()];
        },

        /*切换标签页*/
        changeTabClick() {
            const _key = this._stockStatus2key(this.form.stockStatus);
            if (!_key) {
                return;
            }

            if (this[_key].data.length <= 0) {
                this.requestForm.page = 1;
                this.handleQueryData(_key);
            } else {
                this.echoSelected(this[_key].data, _key);
            }
        },
        echoSelected(tableData, tableName) {
            const _this = this;
            this.$nextTick().then(function () {
                tableData.forEach((row) => {
                    const _table = _this.$refs[tableName].getTable();
                    if (_this.selectDataMap.has(row.code)) {
                        _table.toggleRowSelection(row, true);
                    } else {
                        _table.toggleRowSelection(row, false);
                    }
                });
            });
        },

        /*表格事件方法*/
        deleteSelectRow(goodsCode) {
            this.selectDataMap.delete(goodsCode);
            this.selectData = [...this.selectDataMap.values()];
        },

        /*批量上架后重新查询数据*/
        reloadPageData() {
            this.selectDataMap = new Map();
            this.selectData = [];
            const _key = this._stockStatus2key(this.form.stockStatus);
            this.handleQueryData(_key);
            this.refreshAllTabCount();
        },

        /*历史*/
        rowHistory(code) {
            this.$refs.goodsHistory.show(code);
        },
        /*绑定供应商*/
        bindingSupplier(code) {
            this.supplierGoodsCode = code;
            this.$refs['bindSupplier'].show(code);
        },
        rowDelete(code) {
            this.deleteSelectRow(code);
            this.handleQuery();
        },

        rowSetSpecialGoods() {
            this.handleQuery();
        },
        refreshAllTabCount() {
            const param1 = Object.assign(JSON.parse(JSON.stringify(this.form)), {
                offset: 0,
                limit: 1,
                stockStatus: 1,
                noPriceFlag: null,
            });
            const param2 = Object.assign(JSON.parse(JSON.stringify(this.form)), {
                offset: 0,
                limit: 1,
                stockStatus: 2,
                noPriceFlag: null,
            });
            const param4 = Object.assign(JSON.parse(JSON.stringify(this.form)), {
                offset: 0,
                limit: 1,
                stockStatus: 4,
                noPriceFlag: null,
            });
            const param5 = Object.assign(JSON.parse(JSON.stringify(this.form)), {
                stockStatus: 4,
                offset: 0,
                limit: 1,
                noPriceFlag: true,
            });

            if (this.form.stockStatus != 1) {
                Util.queryTable(this, this.url.page, { params: param1 }, (data) => {
                    this.page.total = data.count;
                });
            }
            if (this.form.stockStatus != 2) {
                Util.queryTable(this, this.url.page, { params: param2 }, (data) => {
                    this.noStockPage.total = data.count;
                });
            }
            if (this.form.stockStatus != 4) {
                Util.queryTable(this, this.url.page, { params: param4 }, (data) => {
                    this.allPage.total = data.count;
                });
            }
            if (this.form.stockStatus != 5) {
                Util.queryTable(this, this.url.page, { params: param5 }, (data) => {
                    this.noPricePage.total = data.count;
                });
            }
        },
        _stockStatus2key(stockStatus) {
            switch (stockStatus + '') {
                case '1':
                    this.form.noPriceFlag = null;
                    return 'page';
                case '2':
                    this.form.noPriceFlag = null;
                    return 'noStockPage';
                case '4':
                    this.form.noPriceFlag = null;
                    return 'allPage';
                case '5':
                    this.form.noPriceFlag = true;
                    return 'noPricePage';
                default:
                    return '';
            }
        },
        handleMultiPrint() {
            const _printId = (this._stockStatus2key(this.form.stockStatus) || 'selected') + 'Print';
            this.handlePrint(_printId);
        },
    },
};
</script>
<style scoped>
.tab-goods-span {
    float: left;
    margin-right: 20px;
}

.select-count-span {
    color: #409eff;
}
</style>
