<template>
    <el-dialog title="变更历史" :visible.sync="dialogTableVisible" width="80%">
        <el-table
            border
            stripe
            style="width: 100%"
            :data="historyData"
            size="mini"
            :highlight-current-row="true"
            max-height="440"
        >
            <el-table-column label="序号" type="index" align="center" fixed="left" />
            <el-table-column property="updater" label="变更人" width="150" />
            <el-table-column property="updateTime" label="变更日期" width="150" />
            <el-table-column property="deptName" label="机构名称" width="150" />

            <el-table-column property="sku.name" label="商品名称" min-width="150" />
            <el-table-column property="sku.type" label="商品类型" width="150">
                <template slot-scope="scope">
                    <span>{{ scope.row.sku.type | goodsType }}</span>
                </template>
            </el-table-column>
            <el-table-column property="fastBar" label="助记码" width="150" />
            <el-table-column property="customBar" label="自编码" width="150" />
            <el-table-column property="sku.specs" label="规格" width="150" />
        </el-table>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogTableVisible = false">取 消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import Util from 'js/Util';

export default {
    name: 'GoodsHistory',
    data() {
        return {
            dialogTableVisible: false,
            historyData: [],
            historyUrl: '/goods/goods/change/page',
            historyPage: {
                page: 1,
                limit: Util.Limit,
                total: Util.Total,
                pageSizes: Util.PageSizes,
                DefaultSize: Util.DefaultPageSize,
                PageStyle: Util.PageStyle,
            },
        };
    },
    methods: {
        show(code) {
            const _this = this;
            _this.dialogTableVisible = true;
            _this.nowSelectGoodsCode = code;
            const _params = {
                params: { bizCode: code },
            };
            Util.queryTable(_this, _this.historyUrl, _params, (data) => {
                _this.historyData = data.data;
            });
        },
        handleHistorySizeChange(size) {
            this.historyPage.page = 1;
            this.historyPage.limit = size;
            this.rowHistory(this.nowSelectGoodsCode);
        },
        handleHistoryCurrentChange(page) {
            this.historyPage.page = page;
            this.rowHistory(this.nowSelectGoodsCode);
        },
        handleHistoryPrevClick(page) {
            this.historyPage.page = page;
            this.rowHistory(this.nowSelectGoodsCode);
        },
        handleHistoryNextClick(page) {
            this.historyPage.page = page;
            this.rowHistory(this.nowSelectGoodsCode);
        },
    },
};
</script>
