import GoodsCommon from 'js/GoodsCommon';
export default {
    // 校验商品的状态是不是已上架
    checkSurplusGoods(goodsSelectedArr) {
        const upShelfFlag = goodsSelectedArr.every((item) => {
            return item.status === GoodsCommon.GoodsStatusEnum.UP_SHELF;
        });
        return upShelfFlag;
    },
};
