<template>
    <el-dialog title="绑定供应商" :visible.sync="visible">
        <el-card style="margin-top: 8px; text-align: left" shadow="never">
            <el-button
                type="primary"
                @click="handleSelectSupplier"
                size="small"
                v-if="hasPrivilege('biz.bind.supplier.edit')"
                >添加供应商
            </el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table border stripe size="mini" style="width: 100%" :data="tableData">
                <el-table-column label="序号" type="index" align="center" />
                <el-table-column property="code" label="编码" width="170" />
                <el-table-column property="name" label="名称" min-width="150" />
                <el-table-column property="linkman" label="联系人" width="90" />
                <el-table-column property="mobile" label="手机号" width="100" />
                <el-table-column property="address" label="地址" min-width="190" />
                <el-table-column label="操作" width="80" header-align="center">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="danger"
                            v-if="hasPrivilege('biz.bind.supplier.edit')"
                            @click="handleDelete(scope.$index)"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div />
            <ef-pagination @pageChange="onPageChange" />
        </el-card>

        <div slot="footer" class="dialog-footer">
            <el-button @click="visible = false" size="small">取 消</el-button>
            <el-button @click="bindSupplier" type="primary" size="small">确 认</el-button>
        </div>
        <pickup-supplier ref="pickupSupplier" @ok="handleSelectSupplierOk" />
    </el-dialog>
</template>

<script>
import EfPagination from 'components/EfPagination';
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import PickupSupplier from 'components/PickupSupplier';

export default {
    components: { PickupSupplier, EfPagination },

    name: 'BindSupplier',
    data() {
        return {
            visible: false,
            form: {
                page: 1,
                limit: Util.Limit,
            },
            url: '/goods/goods/otherSupplier',
            tableData: [],
            codeBackUp: [],
            code: '',
        };
    },
    mounted() {
        this.handleQuery();
    },
    methods: {
        show(code) {
            this.code = code;
            this.tableData = [];
            this.handleQuery(code);
            this.visible = true;
        },
        handleQuery(code) {
            const _this = this;
            const queryCode = code || this.code;
            UrlUtils.QueryRemote(this, _this.url + '?goodsCode=' + queryCode, (res) => {
                _this.tableData = res;
                _this.codeBackUp = _this.tableData.map((d) => d.code);
            });
        },

        handleSelectSupplier() {
            this.$refs.pickupSupplier.show();
        },
        handleDelete(index) {
            const _a = this.tableData;
            _a.splice(index, 1);
            this.tableData = _a;
        },
        onPageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.handleQuery();
        },
        bindSupplier() {
            const _this = this;
            const _new = _this.tableData.map((r) => r.code);
            const _p = {
                addSupplierCodes: _new.filter((r) => {
                    return !this.codeBackUp.includes(r);
                }),
                delSupplierCodes: this.codeBackUp.filter((r) => {
                    return !_new.includes(r);
                }),
            };
            if (_p.addSupplierCodes <= 0 && _p.delSupplierCodes <= 0) {
                //没有数据变化，直接返回
                _this.visible = false;
                return;
            }
            UrlUtils.PatchRemote(this, '/goods/goods/bindSupplier/' + this.code, _p, null, () => {
                _this.$message.success('操作成功');
                _this.visible = false;
            });
        },

        handleSelectSupplierOk(data) {
            const _this = this;
            let _a = this.tableData.concat(data);
            _a = _this.duplicate(_a, 'code');
            _this.tableData = _a;
        },
    },
};
</script>
